import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function OptionsSelector() {
  const location = useLocation();
  const navigate = useNavigate();
  const [options, setOptions] = useState(location.state.options);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [customOption, setCustomOption] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { decision } = location.state;

  const handleOptionToggle = (option) => {
    setSelectedOptions(prev =>
      prev.includes(option)
        ? prev.filter(item => item !== option)
        : [...prev, option].slice(0, 4)
    );
  };

  const handleAddCustomOption = () => {
    if (customOption && options.length < 8) {
      setOptions(prev => [...prev, customOption]);
      if (selectedOptions.length < 4) {
        setSelectedOptions(prev => [...prev, customOption]);
      }
      setCustomOption('');
    }
  };

  const handleCustomOptionKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddCustomOption();
    }
  };

  const handleDeleteOption = (optionToDelete) => {
    setOptions(prev => prev.filter(option => option !== optionToDelete));
    setSelectedOptions(prev => prev.filter(option => option !== optionToDelete));
  };

  const handleGenerateQuiz = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post('/api/questions/quiz', {
        main_question: decision,
        choices: selectedOptions,
        n_questions: 8
      });
      navigate('/quiz', { state: { quizData: response.data, decision } });
    } catch (error) {
      console.error('Error generating quiz:', error);
      setError('An error occurred while generating the quiz. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-4xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-amber-600">
        Select Options
      </h1>
      <div className="w-full max-w-md bg-gray-900 bg-opacity-50 rounded-lg shadow-2xl p-8">
        {options.map((option, index) => (
          <div key={index} className="flex items-center mb-4">
            <button
              onClick={() => handleOptionToggle(option)}
              className={`flex-grow p-3 text-left rounded-l-lg transition duration-300 ${
                selectedOptions.includes(option)
                  ? 'bg-gradient-to-r from-purple-400 to-amber-600 text-white'
                  : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
              }`}
            >
              {option}
            </button>
            <button
              onClick={() => handleDeleteOption(option)}
              className="bg-red-600 text-white px-3 py-3 rounded-r-lg hover:bg-red-700 transition duration-300"
            >
              &times;
            </button>
          </div>
        ))}
        {options.length < 8 && (
          <div className="mt-6 flex">
            <input
              type="text"
              value={customOption}
              onChange={(e) => setCustomOption(e.target.value)}
              onKeyPress={handleCustomOptionKeyPress}
              placeholder="Add custom option"
              className="flex-grow px-4 py-2 bg-gray-800 text-white rounded-l-lg focus:outline-none focus:ring-2 focus:ring-purple-400"
            />
            <button
              onClick={handleAddCustomOption}
              className="bg-gradient-to-r from-purple-400 to-amber-600 text-white px-4 py-2 rounded-r-lg hover:from-purple-500 hover:to-amber-700 transition duration-300"
            >
              Add
            </button>
          </div>
        )}
        {error && (
        <p className="mt-2 text-red-500 text-sm">
            {error}
        </p>
        )}
        <button
          onClick={handleGenerateQuiz}
          disabled={selectedOptions.length < 2 || isLoading}
          className={`mt-2 w-full text-white py-3 rounded-lg transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed transform hover:scale-105 ${
            isLoading 
              ? 'animate-pulse bg-gradient-to-r from-purple-300 to-amber-500' 
              : 'bg-gradient-to-r from-purple-400 to-amber-600 hover:from-purple-500 hover:to-amber-700'
          }`}
        >
          {isLoading ? 'Generating, please wait...' : 'Generate Quiz'}
        </button>
      </div>
    </div>
  );
}

export default OptionsSelector;