import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import OptionsSelector from './components/OptionsSelector';
import QuizPage from './components/QuizPage';
import ResultPage from './components/ResultPage';

function App() {
  return (
    <Router>
      <div className="App min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <div className="container mx-auto px-4 py-8 max-w-3xl">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/options" element={<OptionsSelector />} />
            <Route path="/quiz" element={<QuizPage />} />
            <Route path="/result" element={<ResultPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;