import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function ResultPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { answers, decision } = location.state;

  const result = answers.reduce((acc, curr) => {
    acc[curr] = (acc[curr] || 0) + 1;
    return acc;
  }, {});

  const recommendation = Object.keys(result).reduce((a, b) => result[a] > result[b] ? a : b);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-4xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-amber-600">
        Your Result
      </h1>
      <div className="w-full max-w-md bg-gray-900 bg-opacity-50 rounded-lg shadow-2xl p-8">
        <h2 className="text-2xl font-semibold mb-6 text-white">{decision}</h2>
        <p className="text-lg mb-4 text-gray-300">Based on your answers, we recommend:</p>
        <p className="text-3xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-amber-600">
          {recommendation}
        </p>
        <button
          onClick={() => navigate('/')}
          className="w-full bg-gradient-to-r from-purple-400 to-amber-600 text-white py-3 rounded-lg hover:from-purple-500 hover:to-amber-700 transition duration-300 transform hover:scale-105"
        >
          Start Over
        </button>
      </div>
    </div>
  );
}

export default ResultPage;