import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function QuizPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { quizData, decision } = location.state;
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [randomizedQuizData, setRandomizedQuizData] = useState([]);

  useEffect(() => {
    // Randomize the order of answers for each question
    const randomized = quizData.map(question => {
      const answerEntries = Object.entries(question.answers);
      const shuffled = shuffleArray([...answerEntries]);
      return {
        ...question,
        shuffledAnswers: shuffled
      };
    });
    setRandomizedQuizData(randomized);
  }, [quizData]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleAnswer = (answerKey) => {
    setAnswers([...answers, answerKey]);
    if (currentQuestion < randomizedQuizData.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      // Quiz completed, navigate to result page
      navigate('/result', { state: { answers, decision } });
    }
  };

  if (randomizedQuizData.length === 0) {
    return <div>Loading...</div>;
  }

  const currentQuizItem = randomizedQuizData[currentQuestion];

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-4xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-amber-600">
        Quiz
      </h1>
      <div className="w-full max-w-2xl bg-gray-900 bg-opacity-50 rounded-lg shadow-2xl p-8">
        <h2 className="text-2xl font-semibold mb-6 text-white">{currentQuizItem.question}</h2>
        {currentQuizItem.shuffledAnswers.map(([key, value], index) => (
          <button
            key={index}
            onClick={() => handleAnswer(key)}
            className="mb-4 p-4 w-full text-left rounded-lg bg-gray-800 text-white hover:bg-gradient-to-r hover:from-purple-400 hover:to-amber-600 transition duration-300 transform hover:scale-105"
          >
            {value}
          </button>
        ))}
        <div className="mt-6 text-right text-gray-400">
          Question {currentQuestion + 1} of {randomizedQuizData.length}
        </div>
      </div>
    </div>
  );
}

export default QuizPage;