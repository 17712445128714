import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function HomePage() {
  const [decision, setDecision] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`/api/questions/options?prompt=${encodeURIComponent(decision)}`);
      navigate('/options', { state: { options: response.data, decision } });
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <h1 className="text-5xl font-extrabold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-amber-600">
        Decision Helper AI
      </h1>
      <form onSubmit={handleSubmit} className="w-full max-w-md">
        <div className="relative">
          <input
            type="text"
            value={decision}
            onChange={(e) => setDecision(e.target.value)}
            placeholder="What decision would you like to make?"
            className="w-full px-6 py-4 rounded-full bg-gray-800 bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-purple-400 transition duration-300 text-white placeholder-gray-400"
          />
          <button
            type="submit"
            className="absolute right-2 top-2 bg-gradient-to-r from-purple-400 to-amber-600 text-white px-6 py-2 rounded-full hover:from-purple-500 hover:to-amber-700 transition duration-300 transform hover:scale-105"
          >
            Decide
          </button>
        </div>
      </form>
    </div>
  );
}

export default HomePage;